<template>
  <!-- begin::IncidentTypes index -->
  <div v-if="isAuthenticated">
    <div class="manager-title">
      <div>
        {{ $t("INCIDENTS.TYPES.TITLE") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row pt-6 pb-3 align-items-top">
        <div class="col-md-8 d-flex d-row align-items-top">
          <h5 class="mr-3 mt-3 font-weight-bolder" style="white-space: pre">
            {{ $t("INCIDENTS.TYPES.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="col-md-1 pt-3">
          <span
            class="action-icon"
            :class="[ShowFilters ? 'bg-primary' : '']"
            @click="() => (ShowFilters = !ShowFilters)"
          >
            <inline-svg v-if="ShowFilters" src="/media/icons/filter-alt-1.svg" />
            <inline-svg v-else src="/media/icons/filter-alt-1-primary.svg" />
          </span>
        </div>

        <div class="col-md-3 text-right p-0">
          <button
            class="btn btn-pill button-filter btn-primary mb-3"
            @click="$router.push('/manager/incidents/types/new')"
          >
            <inline-svg src="/media/icons/add.svg" class="mr-3" />
            {{ $t("INCIDENTS.TYPES.TITLE_ADD_1") }}
          </button>
        </div>
      </div>

      <div v-if="ShowFilters" class="row mt-3 py-6 box-filters">
        <div class="col-lg-4">
          <label>
            {{ $t("INCIDENTS.TYPES.CANCELS_1") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByCausesCancellation"
            @change="searchIncidentTypes(false)"
          >
            <option value="" class="text-primary">
              &nbsp;
            </option>
            <option value="true" class="text-primary">
              {{ $t("GENERIC.YES") }}
            </option>
            <option value="false" class="text-primary">
              {{ $t("GENERIC.NO") }}
            </option>
          </select>
        </div>

        <div class="col-lg-4 pt-10 pl-9">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="cleanFilters"
          >
            <inline-svg src="/media/icons/cross-primary.svg" />
            {{ $t("GENERIC.CLEAN_FILTERS") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::Table Results-->
    <v-data-table
      id="IncidentTypesTable"
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="font-weight-bolder">
            {{ item.Name }}
          </td>

          <td>
            {{ item.CausesCancellation ? $t("GENERIC.YES") : $t("GENERIC.NO") }}
          </td>

          <td>
            <span
              class="action-icon"
              @click="
                $router.push(
                  '/manager/incidents/types/edit/' + item.IncidentTypeID
                )
              "
            >
              <inline-svg src="/media/icons/edit-alt-2-primary.svg" />
            </span>

            <span class="action-icon ml-4" @click="deleteIncidentType(item)">
              <inline-svg src="/media/icons/delete-alt-2-primary.svg" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchShipments(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!-- end::IncidentTypes index -->
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

import IncidentTypeService from "@/core/services/api/v2/incidenttype.service";

export default {
  name: "IncidentTypesIndex",

  data() {
    return {
      TableHeaders: [
        { text: this.$t("INCIDENTS.TYPES.NAME_2"), value: "Name" },
        {
          text: this.$t("INCIDENTS.TYPES.CANCELS_1"),
          value: "CausesCancellation"
        },
        { text: this.$t("GENERIC.ACTIONS"), width: 120, sortable: false }
      ],
      options: {},
      SortBy: "Name",
      SortDesc: false,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ShowFilters: false,
      FilterByCausesCancellation: "",
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ]
    };
  },

  computed: {
    ...mapGetters(["isAuthenticated"])
  },

  mounted() {
    // Do first search
    this.searchIncidentTypes(false);
  },

  methods: {
    searchIncidentTypes(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      IncidentTypeService.search(
        this.SearchText,
        this.FilterByCausesCancellation,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.IncidentTypes;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchIncidentTypes(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchIncidentTypes(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchIncidentTypes(false);
    },

    changeItemsCurrentPage() {
      this.searchIncidentTypes(true);
    },

    cleanFilters() {
      this.FilterByCausesCancellation = "";
      this.ShowFilters = false;
      this.searchIncidentTypes(false);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchIncidentTypes(false);
      }, 600);
    },

    deleteIncidentType(item) {
      Swal.fire({
        title: this.$i18n.t("GENERIC.DELETE_ALERT") + "\n" + item.Name,
        text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("GENERIC.DELETE_YES"),
        cancelButtonText: this.$i18n.t("GENERIC.DELETE_NO"),
        customClass: {
          confirmButton: "btn btn-pill btn-danger button-form",
          cancelButton: "btn btn-pill btn-white button-form button-form-primary"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.isConfirmed) {
          IncidentTypeService.deleteProfile(item.IncidentTypeID).then(
            statusCode => {
              if (statusCode >= 400) {
                this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                  title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                Swal.fire({
                  text: this.$i18n.t("GENERIC.DELETE_OK"),
                  icon: "success",
                  confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                  customClass: {
                    confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                  },
                  buttonsStyling: false,
                  focusConfirm: false
                });

                this.searchIncidentTypes(true);
              }
            }
          );
        }
      });
    }
  }
};
</script>
